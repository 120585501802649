import React, { useEffect } from 'react';
import '../current-promotions/current-promotions.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Carousel } from 'react-bootstrap';

export const CurrentPromotions: React.FC = () => {
    const promoImages = [

        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp32.webp",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp33.webp",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp1.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp2.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp3.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp4.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp5.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp6.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp7.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp8.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp9.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp10.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp11.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp12.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp13.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp14.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp15.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp16.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp17.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp18.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp19.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp20.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp21.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp22.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp23.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp24.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp25.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp26.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp27.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp28.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp29.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp30.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp31.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp32.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp33.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp34.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp35.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp36.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp37.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp38.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp39.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp40.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp41.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp42.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp43.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp44.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp45.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp46.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp47.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp48.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp49.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp50.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp51.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp52.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp53.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp54.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp55.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp56.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp57.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp58.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp59.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp60.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp61.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp62.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp63.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp64.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp65.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp66.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp67.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp68.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp69.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp70.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp71.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp72.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp73.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp74.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp75.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp76.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp77.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp78.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp79.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp80.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp81.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp82.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp83.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp84.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp85.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp86.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp87.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp88.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp89.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp90.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp91.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp92.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp93.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp94.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp95.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp96.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp97.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp98.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp99.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp100.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp101.png",
        "https://gagalon-images.s3.eu-north-1.amazonaws.com/current-promotions/webp102.png"


    ];

    useEffect(() => {
        const promoContainer = document.querySelector('.promo-carousel-container');
        if (promoContainer) {
            promoContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    return (
        <div className="promo-carousel-container">
            <Carousel
                indicators={false}
                nextLabel=""
                prevLabel=""
                style={{ width: '100%', height: 'auto' }}
                className="promo-carousel"
            >
                {promoImages.map((image, index) => (
                    <Carousel.Item key={index}>
                        <TransformWrapper
                            initialScale={1}
                            initialPositionX={0}
                            initialPositionY={0}
                        >
                            {({ zoomIn, zoomOut, resetTransform }) => (
                                <>
                                    <TransformComponent>
                                        <img
                                            className="d-block w-100 rounded promo-image"
                                            src={image}
                                            alt={`Promotion ${index + 1}`}
                                        />
                                    </TransformComponent>
                                    <div className="zoom-controls">
                                        <button className="zoom-button" onClick={() => zoomIn()}>+</button>
                                        <button className="zoom-button" onClick={() => zoomOut()}>-</button>
                                        <button className="zoom-button" onClick={() => resetTransform()}>Reset</button>
                                    </div>
                                </>
                            )}
                        </TransformWrapper>

                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};
